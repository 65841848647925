import React from 'react';
import {Shield, Star, Phone, FileCheck, Clock, HandshakeIcon} from 'lucide-react';
import '../styles/main.css';

const services = [
    {
        icon: <FileCheck className="service-icon w-10 h-10"/>,
        text: 'Negocierea și încheierea contractelor de asigurare pe piața internă.'
    },
    {
        icon: <HandshakeIcon className="service-icon w-10 h-10"/>,
        text: 'Negocierea și încheierea contractelor de reasigurare pe piața internă.'
    },
    {
        icon: <Clock className="service-icon w-10 h-10"/>,
        text: 'Acordarea de asistență înainte și pe durata derulării contractelor de asigurare.'
    },
    {
        icon: <Shield className="service-icon w-10 h-10"/>,
        text: 'Acordarea de asistență în legătură cu regularizarea daunelor.'
    },
    {
        icon: <Star className="service-icon w-10 h-10"/>,
        text: 'Efectuarea de inspecții de risc.'
    },
];

export const Services: React.FC = () => {
    return (
        <div className="card-content text-xl font-font-s text-bg-gray p-10 w-full md:w-11/12 gap-5 flex flex-col">
            <h2 className={'uppercase text-4xl'}>STAR INSURANCE</h2>
            <p className="subtitle">
                Desfășoară în limita autorizației de funcționare acordate de
                Comisia de Supraveghere a Asigurărilor următoarele activități:
            </p>

            <div className="services flex flex-col gap-3 items-center md:items-start justify-center">
                {services.map((service, index) => (
                    <div key={index} className="service-item flex flex-row gap-3 items-center">
                        {service.icon}
                        <p>{service.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};