import React from 'react';

export const Iframe: React.FC = () => {
    return (
        <div className={'w-auto bg-white flex flex-col xl:flex-row items-center justify-between mt-5 mb-5'}>
            <iframe
                src="https://www.cursbnr.ro/insert/cursvalutar.php?w=200&b=f7f7f7&bl=dcdcdc&ttc=0a6eab&tc=000000&diff=1&ron=1&cb=1&pics=1"
                width="300"
                height="auto"
                frameBorder="0"
                className="p-5"
            >
            </iframe>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.8874289641745!2d26.102234776882766!3d44.43547920078946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff4566c1a9a7%3A0xca11f73ae6c31c00!2sRENOMIA%20SRBA%20-%20Partener%20Star%20Insurance%20Specialist!5e0!3m2!1sro!2sro!4v1709913445025!5m2!1sro!2sro"
                width="400"
                height="300"
                style={{border: 0}}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="p-5"
            />
            {/*<iframe*/}
            {/*    src="https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=RENOMIA+SRBA+Star+Insurance+Specialist,Bucharest"*/}
            {/*    width="400"*/}
            {/*    height="300"*/}
            {/*    style={{border: 0}}*/}
            {/*    allowFullScreen={true}*/}
            {/*    loading="lazy"*/}
            {/*    referrerPolicy="no-referrer-when-downgrade"*/}
            {/*/>*/}
        </div>
    );
};