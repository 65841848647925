import React from 'react';

export const SideNav: React.FC = () => {
    return (
        null
        // <div className="side-nav">
        //     <div className="nav-items">
        //         <div className="nav-item">Despre noi</div>
        //         <div className="nav-item">Servicii</div>
        //         <div className="nav-item">Legislație</div>
        //         <div className="nav-item">Contact</div>
        //     </div>
        // </div>
    );
};