import React from 'react';
import {Header} from 'components/Header';
import {Services} from 'components/Services';
import {Footer} from 'components/Footer';
import {SideNav} from 'components/SideNav';
import {GoogleAnalytics} from 'components/GoogleAnalytics';
import {Iframe} from "components/Iframe";

const MainPage: React.FC = () => {

    const bgImage = process.env.PUBLIC_URL + '/images/sediu-insurance.jpg';

    return (
        <div className="app">
            <GoogleAnalytics gaCode="G-XXXXXXXXXX" />
            <Header/>
            <div className={'w-full flex flex-col items-center justify-center'}>
                <main className={'main-content flex flex-col items-center max-w-[1440px] bg-main-color shadow-lg p-5'}>
                    <div className="card flex flex-col gap-5 items-center">
                        <div className={'w-full h-[200px] xl:h-[400px]'}
                             style={{
                                 background: `url(${bgImage})`,
                                 backgroundSize: 'contain',
                                 backgroundPosition: 'center',
                                 backgroundRepeat: 'no-repeat'
                             }}
                        >
                        </div>
                        <Services/>
                       <Iframe/>
                    </div>
                </main>
            </div>

            <Footer/>
            <SideNav/>
        </div>
    );
};

export default MainPage;