import React from 'react';
import {Phone, Mail} from 'lucide-react';

export const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer bg-bg-gray min-h-[200px] flex flex-col items-center justify-end">
            <div className="contact-info flex flex-row items-center gap-3 mb-4">
                <div className="flex items-center gap-2">
                    <Phone className="w-5 h-5 text-blue-600"/>
                    <a href="tel:+40123456789" className="text-text-color hover:text-blue-600 transition-colors">
                        +40 123 456 789
                    </a>
                </div>
                <div className="flex items-center gap-2">
                    <Mail className="w-5 h-5 text-blue-600"/>
                    <a href="mailto:contact@starinsurance.com"
                       className="text-text-color hover:text-blue-600 transition-colors">
                        contact@starinsurance.com
                    </a>
                </div>
            </div>
            <div className="footer-copy flex items-center justify-center mb-5">
                <p className="text-text-color text-xl">&copy; {currentYear} Star Insurance</p>
            </div>
        </footer>
    );
};