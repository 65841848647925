import React from 'react';

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void;
    }
}

interface GoogleAnalyticsProps {
    gaCode: string;
}

export const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({ gaCode }) => {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaCode}');
        `;
        document.head.appendChild(script);

        const gaScript = document.createElement('script');
        gaScript.async = true;
        gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaCode}`;
        document.head.appendChild(gaScript);
    }, [gaCode]);

    return null;
};