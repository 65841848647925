import React from 'react';

const navigationLinks = [
    {name: 'Google', url: 'https://www.google.com'},
    {name: 'Mail', url: 'https://mail.google.com'},
    {name: 'Drive', url: 'https://drive.google.com'},
];

export const Header: React.FC = () => {
    const logo = process.env.PUBLIC_URL + '/images/logo.png';

    return (
        <header className="header flex items-center justify-center p-5">
            <div className="container header-content flex flex-col md:flex-row items-center justify-between max-w-[1240px]">
                <div className="logo-container max-w-[210px]">
                    <img src={logo} alt="Star Insurance"/>
                </div>
                <div className="flex gap-4 flex-col md:flex-row">
                    {navigationLinks.map((link, index) => (
                        <a
                            key={index}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="px-4 py-2 bg-main-color text-white rounded hover:shadow-xl transition-colors min-w-[100px] text-center"
                        >
                            {link.name}
                        </a>
                    ))}
                </div>
            </div>
        </header>
                );
                };